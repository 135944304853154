import React, { useEffect, useState } from 'react';
import { getAllUsersData } from '../services/getAllUsersData';
import PageLayout from '../Layout/Layout';
import { useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import Swal from 'sweetalert2';
import Loader from '../components/loader';

function AllUsersData() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [pagination, setPagination] = useState(0);
  let [refresh, setRefresh] = useState(0);
  const navigate = useNavigate();

  const columns = [
    { field: 'id', headerName: 'Id', width: 150 },
    // { field: 'project_name', headerName: 'Project Name', width: 100 },
    { field: 'name', headerName: 'Name', width: 250 },
    { field: 'mobile', headerName: 'Mobile', width: 250 },
    {
      field: 'status',
      headerName: 'Status',
      width: 200,
      renderCell: (cellValues) => {
        if (cellValues.value === '1') {
          return <div>Active</div>;
        } else {
          return <div>Inactive</div>;
        }
      },
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      width: 200,
      renderCell: (cellValues) => {
        if (cellValues.value) {
          const date = new Date(cellValues.value);
          const yyyy = date.getFullYear();
          let mm = date.getMonth() + 1; // Months start at 0!
          let dd = date.getDate();

          if (dd < 10) dd = '0' + dd;
          if (mm < 10) mm = '0' + mm;
          const formattedToday = `${dd}-${mm}-${yyyy}`; //dd + '/' + mm + '/' + yyyy;
          return <div>{formattedToday}</div>;
        } else {
          return <div>N/A</div>;
        }
      },
    },
  ];

  const getAllData = async (page, size) => {
    try {
      const response = await getAllUsersData(page, size);

      console.log(
        'page Number',
        page,
        'limit ',
        size,
        'response',
        response.data.data.users
      );

      if (response.data.success) {
        let result = [];
        // response?.data?.
        response.data.data.users?.map((data, i) => {
          console.log('data', data);
          result.push({
            ind: page * size + i,
            id: data?.id,
            name: data?.name,
            mobile: data?.mobile,
            status: data?.status,
            created_at: data?.created_at,
            updated_at: data?.updated_at,
          });

          setData(result);
          setLoading(false);
          setPagination(response?.data?.data?.totalCount);
        });
      } else {
        Swal.fire({
          title: '',
          text: 'Error while fetching users details',
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: 'Got it',
        }).then((result) => {
          if (result.isConfirmed) {
            navigate('/main');
          }
        });
      }
    } catch (err) {
      console.log('Error', err);
    }
  };

  useEffect(() => {
    console.log(page, size);
    getAllData(page, size);
  }, [page, size]);
  return (
    <>
      <PageLayout>
        <div className="">
          {' '}
          {loading ? (
            <Loader />
          ) : data?.length === 0 ? (
            <div className="result-not-found">Data Not Found</div>
          ) : (
            <>
              <div
                className=""
                style={{ height: 400, width: '95%', marginTop: '10px' }}
              >
                <div className="d-flex justify-content-between ">
                  <button
                    type="button"
                    style={{
                      background: '#009bdc',
                    }}
                    className="btn ms-3 mb-2 mt-2"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    Go Back
                  </button>
                  {/* <button className='btn btn-info ms-3 mb-2' type='button' onClick={handleExportClick} >Downlaod</button> */}
                </div>

                <DataGrid
                  sx={{ marginLeft: '15px' }}
                  rows={data}
                  columns={columns}
                  rowsPerPageOptions={[10, 20, 50]}
                  pagination
                  pageSize={size}
                  rowCount={pagination}
                  paginationMode="server"
                  onPageChange={(newPage) => {
                    setPage(newPage + 1);
                    console.log(newPage);
                  }}
                  onPageSizeChange={(newPageSize) => setSize(newPageSize)}
                  autoHeight
                  {...data}
                />
              </div>
            </>
          )}
        </div>
      </PageLayout>
    </>
  );
}

export default AllUsersData;
