function convertStatusCode(status) {
  try {
    switch (status) {
      case 0:
        // Handle status code 0
        return 'Coupon Generated';
        break;
      case 1:
        // Handle status code 1
        return 'Checking Status';
        break;
      case 2:
        // Handle status code 2
        return 'Coupon Redeemed';
        break;
      case 3:
        // Handle status code 3
        return 'Coupon is in Active State';
        break;
      case 4:
        return 'Order is Cancelled';
      case 5:
        // Handle status code 3
        return 'Admin Activate Coupon';
        break;
      default:
        // Handle other status codes
        return 'Admin Activate Coupon';
    }
  } catch (err) {
    console.log('error in converting status of coupon');
    return err;
  }
}
export function convertStatusCodeforButton(statusCode) {
  try {
    switch (statusCode) {
      case 'Coupon Generated':
        // Handle status code 0
        return 'Activated';
        break;
      case 'Checking Status':
        // Handle status code 1
        return 'Checking';
        break;
      case 'Coupon Redeemed':
        // Handle status code 2
        return 'Redeemed';
        break;
      case 'Order is Cancelled':
        // Handle status code 2
        return 'Cancelled';
        break;
      case 'Coupon is in Active State':
        // Handle status code 3
        return 'Active State';
        break;

      default:
        // Handle other status codes
        return 'Unknown';
    }
  } catch (err) {
    console.log('error in converting status of coupon');
    return err;
  }
}

export function showUserStatus(status) {
  try {
    switch (status) {
      case '1':
        // Handle status code 0
        return 'Active';
        break;

      default:
        // Handle other status codes
        return 'Inactive';
    }
  } catch (err) {
    console.log('error in converting status of coupon');
    return err;
  }
}

export default convertStatusCode;
